interface AppConfig {
  api: {
    graphql_endpoint: string
  }
  buildNumber: string
  cognito: {
    userPoolId: string
    userPoolWebClientId: string
    region: string
  }
  environment: {
    name: string
  }
  google: {
    apiKey: string // used by AddressAutocomplete (shared package)
  }
  greenId: {
    accountId: string
    isTestMode: boolean
    apiCode: string
  }
  featureFlags: {
    enableBankGuaranteeSwitch: boolean
    enableInvitePartiesToDraft: boolean
    enablePropertyBackedBG: boolean
  }
  webAppUrl: string
}

const getConfig = <T>(path: string): Promise<T> => {
  return fetch(path).then(response => response.json())
}

export { AppConfig, getConfig }
